/*===============================================
Template Name: Digicrew - IT Solutions Services HTML5 Template
================================================*/


/*============================
 Navibar Menu Area Css
==============================*/
.navibar_nav_manu {
    background: #fff;
    transition: .5s;
    margin-bottom: 0;
    z-index: 999;
    position: relative;
}
.navibar_nav_manu.transparent_menu {
    background: transparent;
    margin-bottom: -87px;
    position: relative;
}
.sticky {
    left: 0;
    margin: auto;
    position: fixed !important;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    background: var(--headerColor) !important;
	transition:.5s;
}
/* Navibar Menu Css*/
.navibar_menu {
    text-align: right;
 
}
.navibar_menu ul {
    text-align: right;
    list-style: none;
    display: inline-block;
}
.navibar_menu>ul>li {
 display: inline-block;
 position: relative;
}
.navibar_menu > ul > li > a {
    display: block;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    position: relative;
    color: #232323;
    font-weight: 600;
    text-transform: capitalize;
    margin: 30px 10px;
    font-size: 17px;
}
.white .navibar_menu > ul > li > a {
    color: #fff;
}
.navibar_menu ul li:last-child a {
 margin-right: 0px;
}
.navibar_menu>ul>li.current>a {
 color: #00247E;
}
.white .navibar_menu>ul>li.current>a {
 color: #fff;
}
/* sub menu style */
.navibar_menu ul .sub-menu {
    position: absolute;
    left: 0;
    top: 130%;
    width: 217px;
    text-align: left;
    background: #fff;
    margin: 0;
    z-index: 1;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    border-top: 2px solid var(--mainColor);
    
}
.navibar_menu ul li:hover>.sub-menu {
 opacity: 1;
 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
 visibility: visible;
 top: 100%;
 z-index: 9;
}
.navibar_menu ul .sub-menu li {
 position: relative;
}
.navibar_menu ul .sub-menu li a {
    display: block;
    padding: 12px 20px;
    margin: 0;
    line-height: 1.3;
    letter-spacing: normal;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    visibility: inherit !important;
    color: #333 !important;
}
.navibar_menu ul .sub-menu li:hover>a,
.navibar_menu ul .sub-menu .sub-menu li:hover>a,
.navibar_menu ul .sub-menu .sub-menu .sub-menu li:hover>a,
.navibar_menu ul .sub-menu .sub-menu .sub-menu .sub-menu li:hover>a {
 background:var(--mainColor);
 color: #fff !important;
}

/* sub menu 2 */
.navibar_menu ul .sub-menu .sub-menu {
 left: 100%;
 top: 130%;
 opacity: 0;
 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
 visibility: hidden;
}
.navibar_menu ul .sub-menu li:hover>.sub-menu {
 opacity: 1;
 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
 visibility: visible;
 top: 0%;
}

/* sub menu 3 */
.navibar_menu ul .sub-menu .sub-menu li {
 position: relative;
}
.navibar_menu ul .sub-menu .sub-menu .sub-menu {
 right: 100%;
 left: auto;
 top: 130%;
 opacity: 0;
 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
 visibility: hidden;
}
.navibar_menu ul .sub-menu .sub-menu li:hover>.sub-menu {
 opacity: 1;
 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
 visibility: visible;
 top: 0%;
}

/* sub menu 4 */
.navibar_menu ul .sub-menu .sub-menu .sub-menu li {
 position: relative;
}
.navibar_menu ul .sub-menu .sub-menu .sub-menu .sub-menu {
}
.navibar_menu ul .sub-menu .sub-menu .sub-menu li:hover>.sub-menu {
 opacity: 1;
 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
 visibility: visible;
 top: 0%;
}

.donate-btn-header {
    display: inline-block;
    margin-left: 16px;
}
a.dtbtn {
    -moz-user-select: none;
    background: var(--mainColor);
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    margin-bottom: 0;
    padding: 10px 30px;
    text-align: center;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    border-radius: 5px;
    border: 2px solid var(--mainColor);
    font-weight: 600;
}
a.dtbtn:hover {
    border: 2px solid #00247E;
    background: #00247E;
    color: #fff;
}
.navibar_menu li a:hover:before{
	width:101%;
}
.navibar_nav_manu .logo_img{
    img{
        width: 156px;
    }
}
.navibar_nav_manu.sticky .logo_img {
    display: none;
}
.main_sticky {
    display: none;
}
.navibar_nav_manu.sticky .main_sticky {
    display: inherit;

    img{
        width: 156px;
    }
}
.footer-bottom-logo{
    img{
        width: 156px;
    }
}
.navibar_nav_manu.sticky .techno_menu li a {
    color: #fff;
}
.navibar_nav_manu.sticky a.dtbtn {
    color: var(--mainColor);
	background:#fff;
	border: 2px solid #fff;
}
.navibar_nav_manu.sticky a.dtbtn:hover {
    color: var(--mainColor);
}
.mobile-menu.mean-container {
	overflow: hidden;
}

/*====================================================
--<	Main Timeline Work Css -->
======================================================*/

.main-timeline{
    overflow: hidden;
    position: relative;
}
.main-timeline:before{
    content: "";
    width: 3px;
    height: 100%;
    background: var(--mainColor);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.main-timeline .timeline{
    width: 50%;
    float: right;
    padding: 50px 50px 10px 50px;
    margin-top: 50px;
    position: relative;
}
.main-timeline .timeline:before{
    content: attr(data-heading);
    width: 140px;
    height: 40px;
    line-height: 35px;
    border-radius: 20px;
    background: #00247e;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    position: absolute;
    top: 3px;
    left: -32px;
}
.main-timeline .timeline:after{
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #00247e;
    border: 2px solid var(--mainColor);
    position: absolute;
    bottom: 53px;
    left: -8px;
}
.main-timeline .timeline-content{
    display: block;
    padding: 25px 150px 25px 25px;
    border-radius: 0 70px 70px 0;
    background: var(--mainColor);
    color: #fff;
    position: relative;
    margin-top: 35px;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-content:before{
    content: "";
    border-bottom: 20px solid transparent;
    border-right: 62px solid var(--mainColor);
    position: absolute;
    bottom: 14px;
    left: -53px;
    transform: rotate(30deg);
}
.main-timeline .icon{
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    margin: auto;
    font-size: 40px;
    color: var(--mainColor);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
}
.main-timeline .title5{
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 5px 0;
    color: #fff;
}
.main-timeline .description5{ font-size: 14px; }
.main-timeline .timeline:nth-child(2n){ padding: 50px 50px 10px 50px; }
.main-timeline .timeline:nth-child(2n):before{
    left: auto;
    right: -40px;
}
.main-timeline .timeline:nth-child(2n):after{
    left: auto;
    right: -10px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content{
    border-radius:70px 0 0 70px;
    padding: 25px  25px 25px 150px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content:before{
    border-right: 68px solid var(--mainColor);
    bottom: 21px;
    left: auto;
    right: -50px;
    transform: rotate(-226deg);
}
.main-timeline .timeline:nth-child(2n) .icon{
    right: auto;
    left: 25px;
}
.main-timeline .timeline:nth-child(2){ margin-top: 210px; }
.main-timeline .timeline:nth-child(odd){ margin: -220px 0 50px 0; }
.main-timeline .timeline:nth-child(even){ margin-bottom: 210px; }
.main-timeline .timeline:first-child,
.main-timeline .timeline:last-child:nth-child(even){ margin: 0 0 30px 0; }
.main-timeline .timeline:last-child:nth-child(even){ margin-bottom: 0; }
@media only screen and (max-width: 1200px){
    .main-timeline .timeline{ padding: 50px 0 10px 50px; }
    .main-timeline .timeline:nth-child(2n){ padding: 50px 50px 10px 0; }
    .main-timeline .timeline-content{ padding: 25px 130px 25px 25px;}
    .main-timeline .timeline:nth-child(2n) .timeline-content{ padding: 25px 25px 25px 130px; }
}
@media only screen and (max-width: 990px){
    .main-timeline .timeline,
    .main-timeline .timeline:nth-child(2n){ padding-top: 20px; }
    .main-timeline .icon{ right: 15px; }
    .main-timeline .timeline:nth-child(2n) .icon{ left: 15px; }
}
@media only screen and (max-width: 767px){
    .main-timeline:before,
    .main-timeline .timeline:after,
    .main-timeline .timeline .timeline-content:before{ display: none; }
    .main-timeline .timeline,
    .main-timeline .timeline:nth-child(even),
    .main-timeline .timeline:nth-child(odd){
        width: 100%;
        float: none;
        padding: 50px 0 0 0;
        margin: 0 0 30px 0;
    }
    .main-timeline .timeline:before,
    .main-timeline .timeline:nth-child(2n):before{
        left: 0;
        transform: translateX(0);
    }
    .main-timeline .timeline:nth-child(2n) .timeline-content{
        padding: 25px 120px 25px 25px;
        border-radius: 0 70px 70px 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon{
        right: 20px;
        left: auto;
    }
}

/*====================================================
--<	Header Top Menu Area Css -->
======================================================*/
.header_top_menu_address_inner ul {
    list-style: none;
}
.header_top_menu_address_inner ul li {
    display: inline-block;
}
.header_top_menu_icon_inner ul {
    list-style: none;
	text-align:right;
}
.header_top_menu_icon_inner ul li {
    display: inline-block;
}
.header_top_menu_address_inner ul li a i {
    font-size: 16px;
    margin-right: 10px;
    color: #fff;
}
.header_top_menu_address_inner ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
	margin-right:15px;
}
.header_top_menu_icon_inner ul li a{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
	margin:0 5px;
}
/*====================================================
--<	Slider Area Css -->
======================================================*/
.slider1 
{
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    height: 900px;
    position: relative;
}
.slider1::before 
{
	background: #222222 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	width: 100%;
 }
.slider2 
{
    background-image: url(../images/slider2.jpg);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    height: 900px;
    position: relative;
}
.slider2::before {
	background: #222222 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.7;
	position: absolute;
	top: 0;
	width: 100%;
 }
.slider3 
{
    background-image: url(../images/slider3.jpg);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    height: 900px;
    position: relative;
}
.slider3::before {
	background: #222222 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.7;
	position: absolute;
	top: 0;
	width: 100%;
 }
.slider4 
{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
}
.slider4::before {
	background: #222222 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.8;
	position: absolute;
	top: 0;
	width: 100%;
 }
.slider5 
{
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
   
}
.slider5::before {
	background: #222222 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.4;
	position: absolute;
	top: 0;
	width: 100%;
 }
.slider6 {
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
}
.slider6::before {
	background: var(--footerColor) none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.8;
	position: absolute;
	top: 0;
	width: 100%;
 }

.slider_text_inner {
    display: inline-block;
}
.slider_text_inner h1  {
    font-size: 65px;
    text-transform: none;
    font-weight: 800;
    color: #fff;
    margin: 0;
    margin: 0;
    line-height: 1.1;
    max-width: 800px;
}
.slider_text_inner h1 span{
	color: var(--mainColor);
}
.slider_text_inner h5  {
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0 0 24px;
    letter-spacing: 1px;
}

/*.slider_text_inner p {
    width: 70%;
    font-size: 16px;
    padding: 29px 0 22px;
    color: #fff;
}*/
.slider_text_desc p {
    color: #fff;
    width: 62%;
    font-size: 18px;
}
.text_center .slider_text_desc p {
    margin: auto;
}

/* Button Css */
.button a {
    background: var(--mainColor);
    padding: 16px 32px;
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
}
.button.color_two a {
    background: #00247E;
}
.button.color_two a:before {
    background: var(--mainColor);
}
.button.two a {
    padding: 14px 35px;
}
.button.three a {
    background: #fff;
    padding: 14px 35px;
    color: var(--mainColor);
}
.button.style-four a {
    background: transparent;
    padding: 10px 30px;
    border-radius: 30px;
    color: var(--mainColor);
    border: 2px solid var(--mainColor);
}
.button.style-four a:before {
    background: var(--mainColor);
}
.button .active {
    background: #00247E;
    margin-left: 25px;
}
.button .active:before {
    background: var(--mainColor);
}
.button a i {
    margin-left: 5px;
	
}
.button a:before {
    position: absolute;
    content: "";
    top: 0px;
    height: 100%;
    left: -25%;
    width: 0%;
    background: #00247E;
    transform: skew(50deg);
    transition-duration: .6s;
    z-index: -1;
}
.button a:hover {
    color: #fff;
}
.button a:hover:before {
    width: 180%;
}

/* Video Css */
.slider-video {
    position: relative;
}
.slider-video .video-icon {
    position: absolute;
    right: 0;
    top: -165px;
}
.slider-video.two .video-icon {
    left: 262px;
    top: -74px;
}
.appointment .slider-video.two .video-icon {
    left: 0;
    top: 0;
}
.single-video2 .video-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.video-icon a {
    width: 75px;
    height: 75px;
    line-height: 75px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    position: relative;
    color: var(--mainColor);
    -webkit-animation: ripple-white 1s linear infinite;
    animation: ripple-blue 1s linear infinite;
    -webkit-transition: .5s;
    background: #fff;
    font-size: 28px;
    z-index: 44;
}
.slider10 .video-icon a {
    border-radius: 0;
}
@-webkit-keyframes ripple-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0)
    }
}

@keyframes ripple-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3), 0 0 0 30px rgba(255,255,255, 0);
        box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3), 0 0 0 30px rgba(255,255,255, 0)
    }
}

/* Owl Nav Css */
.owl-prev {
    position: absolute;
    left: -60px;
    top: 50%;
	transform:translateY(-50%);
	opacity:1;
	transition:.3s;
}
.owl-next {
    position: absolute;
    right: -60px;
    top: 50%;
	transform:translateY(-50%);
	opacity:1;
	transition:.3s;
}
.owl-carousel:hover .owl-prev{
 left:30px;
}
.owl-carousel:hover .owl-next{
right:30px;
}
.owl-next i,
.owl-prev i{
	background-color: transparent;
    border-radius: 100%;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    height: 60px;
    width: 60px;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 55px;
    color: #fff;
    border: 2px solid #FFF;
	text-align:center;
}
.owl-next i:hover,
.owl-prev i:hover{
	background-color: rgba(255, 255, 255, 0.2);
}
/*====================================================
--<	Banner Area Css -->
======================================================*/


/*====================================================
--<	Nivo Slider Area Css -->
======================================================*/
.nivo_slider_area {
	position: relative;
}
.nivo-caption {
	background: rgba(0, 0, 0, 0.0);
	height: 100%;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.em_slider_inner {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9999;
}

/* slider title */
.em-slider-title {
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 23px;
	text-transform: uppercase;
}
.em-slider-main-title {
    color: #fff;
    font-size: 120px;
    font-weight: 800;
}
.em-slider-sub-title {
    color: #fff;
    font-size: 33px;
    font-weight: 400;
}
.em-nav-slider-title-center {
    color: #fff;
    font-size: 55px;
    font-weight: 800;

}
.em-nav-slider-title-centerspan{
	color: var(--mainColor);
}
.em-slider-up-title {
	font-size: 38px;
	color: #fff;
	font-weight: 300;
	padding-bottom: 8px;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.em-slider-sub-em-title {
	font-size: 24px;
	color: #fff;
	font-weight: 500;
}
.em-slider-descript {
	font-size: 17px;
	margin-bottom: 20px;
	width: 59%;
	z-index: 999;
	font-weight: 400;
	color: #ddd;
	margin-top: 10px;
}
.text-left .em-slider-descript {
	margin-left: 0;
	margin-right: auto;
}

.text-center .em-slider-descript {
	margin: 11px auto 28px;
}
.text-right .em-slider-descript {
	margin-right: 0;
	margin-left: auto;
}
.text-center {
	text-align: center;
}
/* slider button */
.em-slider-button.wow.bounceInUp.em-button-button-area.animated {
	padding-top: 19px;
}
.em-button-button-area a {
	border: 1px solid #ccc;
	color: #fff;
	display: inline-block;
	font-size: 15px;
	margin-right: 10px;
	margin-top: 12px;
	padding: 15px 41px;
	position: relative;
	text-transform: uppercase;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	z-index: 999;
	letter-spacing: 1px;
	border-radius: 5px;
}
a.em-active-button {
	background: var(--mainColor);
	border-color: var(--mainColor);
}

.em-button-button-area a:hover {
	color: #fff;
	background: var(--mainColor);
	border-color: var(--mainColor);
}

a.em-active-button:hover {
	background: transparent;
	border-color: #fff;
}

.em-slider-half-width {
	width: 50%;
}

.em-slider-left {
	padding-right: 30px;
}

.em-slider-right {
	padding-left: 30px;
}

.em-slider-full-width {
	width: 85%;
}

.em-slider-half-width .em-slider-descript {
	width: 100%;
}

/* directionNav */
.em-nivo-slider-wrapper .nivo-directionNav a {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 75px;
    width: 50px;
    line-height: 68px;
    text-align: center;
    display: block;
    border-radius: 50%;
    color: var(--mainColor);
    font-size: 40px;
    left: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    border-radius: 0;
    background: #fff;
    margin-right: 30px;
}
.em-nivo-slider-wrapper .nivo-directionNav .nivo-prevNav {
	margin-left: 30px;
}
.em-nivo-slider-wrapper .nivo-directionNav .nivo-nextNav {
	left: auto;
	right: 0;
}
.em-nivo-slider-wrapper .nivo-directionNav a:hover {
	background: var(--mainColor);
	border-color: var(--mainColor);
	color: #fff;
}
.em-nivo-slider-wrapper:hover .nivo-directionNav a {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
	left: 50px;
}

.em-nivo-slider-wrapper:hover .nivo-directionNav .nivo-nextNav {
	left: auto;
	right: 50px;
}
/* controlNav */

.em-nivo-slider-wrapper .nivo-controlNav {
	bottom: 50px;
	padding: 0;
	position: absolute;
	width: 100%;
	z-index: 9;
	display: none;
}

.em-nivo-slider-wrapper .nivo-controlNav a {
	background: #000 none repeat scroll 0 0;
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	height: 25px;
	margin: 0 5px;
	width: 25px;
	color: #fff;
	line-height: 25px;
}

.em-nivo-slider-wrapper .nivo-controlNav a:hover,
.em-nivo-slider-wrapper .nivo-controlNav a.active {
	background: #FFA700 none repeat scroll 0 0;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.cd-headline.clip span {
  display: inline-block;
  padding: 0 0 0;
}
.cd-headline.clip span b{
	display:inline-block;
	color:#fff;
	text-transform:uppercase;
}
/* Appointment Css */
.appointment {
    position: relative;
    z-index:99 !important;
}
.sign-up-form-wrap{
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
    
}
.form-control {
    height: 54px;
    background-color: #fff;
    border-color: transparent;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border: 1px solid #e6e6e6;
    padding: 6px 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
}
.form-group .btn {
    background: var(--mainColor);
    border: 0;
    display: inline-block;
    text-align: center;
    border-radius: 7px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    color: #fff;
	width:100%;
	height: 54px;
}
.appointment_title {
    font-weight: 800;
}
.slider_bottom_shape_inner {
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    z-index:-1;
}

/*
<!-- ============================================================== -->
<!-- Breatcome top header Css -->
<!-- ============================================================== -->*/
.breatcome_topheader {
    //background-image: url(../images/bg-banner4.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 550px;
}
.breatcome_topheader::before {
	background: #000 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.7;
	position: absolute;
	top: 0;
	width: 100%;
 }

.breatcome_title {
    text-align: center;
}
.breatcome_title_inner h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
}
.breatcome_content ul li {
    display: inline-block;
}
.breatcome_content ul li a {
    color: #fff;
    opacity: .7;
	transition:.5s;
}
.breatcome_content ul li i {
    color: #fff;
    opacity: .7;
    padding: 0 5px;
}
.breatcome_content ul li span {
    color: #fff;
    opacity: .7;
}
.breatcome_content ul li a:hover {
    opacity:1;
}

.service-details-wrap{
    img{
        width: 100%;
    }
}
/*====================================================
--<	Section Title Css -->
======================================================*/
.section_sub_title h6 {
    letter-spacing: 1px;
    color: var(--mainColor);
    font-size: 20px;
}
.section_title.text_center {
	text-align:center;
}
.section_main_title h1,
.section_main_title h2 {
    font-weight: 800;
	margin:0;
}
.text_left .section_main_title h1 {
    margin: 0;
    letter-spacing: -1px;
}
.section_main_title h1 span{
	color:var(--mainColor);
}
.section_content_text p {
    font-size: 18px;
}
.text_center .section_content_text p {
    width: 75%;
    margin: auto;
    font-size: 19px;
}
.section_content_text.bold p {
    font-size: 22px;
    font-weight: 600;
}
.section_title.white .section_main_title h1,
.section_title.white .section_main_title h2 {
    color: #fff;
}
.section_title.white .section_sub_title h6 {
    color: #fff;
}
.section_title.white .section_content_text p {
    color: #fff;
}
.section_button {
    text-align: right;
}
.phone_number h5 {
    letter-spacing: 1px;
    font-weight: 500;
}
.em_bar {
	background: #f6b200 none repeat scroll 0 0;
	height: 2px;
	margin: 0 auto 5px;
	width: 62px;
	
}
.text_left .em_bar {
    margin: 0;
}
.em_bar_bg {
	height: 5px;
    width: 90px;
    background: #f6b200;
    margin: 20px auto;
    position: relative;
    border-radius: 30px;
}
.em_bar_bg:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--mainColor);
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}
@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
            transform: translateX(88px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
            transform: translateX(88px);
  }
}

/*====================================================
--<	Flipbox Top Feature Area Css -->
======================================================*/
.nagative_margin {
    position: relative;
    margin-top: -100px;
}
.nagative_margin3 {
	position: relative;
	margin-top: -100px;
	z-index: 99;
}
.nagative_margin4 {
    position: relative;
    margin-top: -225px;
}
.nagative_margin5 {
    position: relative;
    margin-top: -70px;
}
.top_feature .whychoose_flipbox .whychoose_flipbox_font {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.10);
    border: 0;
}
.top_feature .whychoose_flipbox .whychoose_flipbox_back {
    background: var(--mainColor);
	box-shadow:0 5px 20px rgba(0,0,0,0.10);
	border-radius:8px;
}
.top_feature .whychoose_flipbox .whychoose_flipbox_inner {
    padding: 0 30px;
}
.top_feature .whychoose_flipbox .whychoose_flipbox_back::before {
    display:none;
}
.top_feature .icon {
    color: var(--mainColor);
    font-size: 45px;
    display: inline-block;
    line-height: 46px;
}
.top_feature .whychoose_flipbox .flipbox_title h3 {
    font-size: 20px;
    color: #232323;
    font-weight: 700;
	font-family: var(--fontFamily);
}

/* Top Feature Style Two */

.top_feature.two .whychoose_flipbox .whychoose_flipbox_font, .top_feature.two .whychoose_flipbox .whychoose_flipbox_back {
    min-height: 223px;
}
.top_feature.two .whychoose_flipbox .whychoose_flipbox_back {
    background: #fff;
}
.top_feature.two .whychoose_flipbox .whychoose_flipbox_inner {
    padding: 0 24px;
}
.top_feature.two .icon {
    color: #fff;
    font-size: 40px;
    line-height: 90px;
    height: 90px;
    width: 90px;
    background: var(--mainColor);
    border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
}
.top_feature.two .whychoose_flipbox .flipbox_title h3 {
    padding-bottom: 0;
	font-size:21px;
}
.top_feature.two .whychoose_flipbox .whychoose_flipbox_back .flipbox_title h3 {
    color: #232323;
	font-size:21px;
}
.top_feature.two .whychoose_flipbox_back .flipbox_desc p {
    color: #616161;
}


/* Single Feature Card Style */
.single_feature_card {
    text-align: center;
    padding: 40px 24px;
    border: 1px solid #e4ecf9;
    position: relative;
    z-index: 1;
    background: #fff;
	margin-bottom:30px;
}

.single_feature_card:before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 45px;
    height: 45px;
    content: "";
    background: #adc7f1;
    transition: .3s;
    z-index: -1;
}
.single_feature_six_seven_icon .icon {
    color: var(--mainColor);
	transition:.5s;

    img{
        width: 55px;
    }
}
.single_feature_card_content h4 {
    transition: .5s;
    padding-top: 15px;
}
.single_feature_card_content p {
    padding-top: 8px;
    transition: .5s;
}
.single_feature_card:hover:before{
	width: 100%;
    height: 100%;
	background:var(--mainColor);
}
.single_feature_card:hover .single_feature_six_seven_icon .icon,
.single_feature_card:hover .single_feature_card_content h4,
.single_feature_card:hover .single_feature_card_content p{
	color:#fff;
}

/*====================================================
--<	Whychoose Flipbox Area Css -->
======================================================*/
.whychoose_flipbox_font {
    border: 1px solid var(--mainColor);
    border-radius: 8px;
}
.flipbox .section_main_title h1 {
    color: #fff;
}
.flipbox .section_sub_title h6 {
    color: #fff;
}
.flipbox .section_title{
	margin-bottom:30px;
}
.whychoose_flipbox {
	perspective: 1000px;
	position: relative;
	transform-style: preserve-3d;
	-webkit-perspective: 1000px;
	-webkit-transform-style: preserve-3d;
	z-index: 1;
}
.whychoose_flipbox .whychoose_flipbox_font,
.whychoose_flipbox .whychoose_flipbox_back {
	min-height: 250px;
	height: auto;
	box-shadow: 0px 1px 51.3px 5.7px rgba(137,143,156,.15);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: rotateX(0);
	-webkit-transform: rotateX(0);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	transition: transform .7s ease, -webkit-transform .7s ease;
	position: relative;
    background-size: cover;
    background-position: center;
}
.whychoose_flipbox .whychoose_flipbox_back::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,transparent -80%,#00247e);
}
.icon {
    font-size: 45px;
    line-height: 45px;
}
.whychoose_flipbox .whychoose_flipbox_inner {
	text-align: center;
	padding: 0 25px;
	border-radius: 2px;
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	perspective: inherit;
	-webkit-perspective: inherit;
	outline: transparent solid 1px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
	transform: translateY(-50%) translateZ(60px) scale(0.94);
	z-index: 2;
}
.whychoose_flipbox_icon {
	font-size: 45px;
	text-align: center;
	display: inline-block;
	color: #fff;
	overflow: hidden;
}
.whychoose_flipbox_font .flipbox_desc p {
    color: #fff;
}
.whychoose_flipbox .flipbox_title h3 {
    font-size: 22px;
    padding-bottom: 13px;
    text-transform: capitalize;
    -webkit-transition: all 0.7s ease 0s;
    transition: all 0.7s ease 0s;
    color: #fff;
}
.service_box_title h3 {
    font-size: 22px;
    padding-bottom: 13px;
    text-transform: capitalize;
    -webkit-transition: all 0.7s ease 0s;
    transition: all 0.7s ease 0s;
}
.whychoose_flipbox .whychoose_flipbox_back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	border-radius: 2px;
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
.whychoose_flipbox .whychoose_flipbox_back .flipbox_button a {
    text-transform: capitalize;
    margin-top: 22px;
    background: #fff;
    color: var(--mainColor);
    display: inline-block;
    padding: 4px 25px;
    border-radius: 5px;
    border: 2px solid #fff;
    transition: .5s;
}
.whychoose_flipbox_back .flipbox_desc p{
    color: #fff;
}
.whychoose_flipbox .whychoose_flipbox_back .flipbox_button a i {
	display: none;
}
.whychoose_flipbox .whychoose_flipbox_back .flipbox_title h3 {
	padding-bottom: 16px;
	padding-top: 0;
	color: #fff;
	margin-bottom: 0;
}
.whychoose_flipbox .whychoose_flipbox_font .flipbox_desc,
.whychoose_flipbox .whychoose_flipbox_back .flipbox_desc {
	color: #fff;
}

.slider4{
    .service_box_title h3{
        color: #fff;
    }
    .service_box_desc{
        color: #fff;
    }
}
/* Flipbox Hover Css */
.whychoose_flipbox:hover .whychoose_flipbox_font {
	-webkit-transform: rotateX(-180deg);
	transform: rotateX(-180deg);
}
.whychoose_flipbox:hover .whychoose_flipbox_back {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
}
.whychoose_flipbox .whychoose_flipbox_back .flipbox_button a:hover {
	color: #fff;
	border-color: var(--mainColor);
	background: var(--mainColor);
}

.pages .whychoose_flipbox .whychoose_flipbox_font .flipbox_title h3 {
    color: #444;
}
.pages .whychoose_flipbox_font .flipbox_desc p {
    color: #666;
}
/*====================================================
--<	Service List Css -->
======================================================*/
.all-service-list ul li {
    margin-bottom: 2px;
    transition: .5s;
    list-style: none;
}
.all-service-list ul li a {
    position: relative;
    display: block;
    padding: 14px 10px 14px 18px;
    background-color: #F3F6F9;
    font-weight: 600;
    font-size: 17px;
    transition: .5s;
    color: #232323;
    font-family: var(--fontFamily);
}
.all-service-list ul li a:hover {
    background: var(--mainColor) !important;
    color: #fff !important;
}
.all-service-list ul li a i {
    float: right;
    font-size: 17px;
    font-weight: 600;
    position: relative;
    margin-top: 5px;
}

.widget-categories ul li, .widget-recent-entries ul li, .widget-recent-entries-custom ul li {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    margin: 0;
}
.widget-service-list ul li, .widget-recent-entries ul li, .widget-recent-entries-custom ul li {
    padding: 2px 0;
    margin: 0;
}
.widget1 a  {
    display: block;
    font-size: 14px;
    margin-top: 20px;
}

.color-secondary {
    color: var(--mainColor);
}
.widget .widget-title {
    margin-bottom: 35px;
}

/*====================================================
--<	404 Error Section Css -->
======================================================*/

.error-page-inner {
    
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
   
  }
  
  .error-page-inner .inner {
    text-align: center;
  }
  
  .error-page-inner .inner h1.title {
    font-size: 200px;
    line-height: 1;
    margin-bottom: 0;
    font-weight: 800;
    color:var(--mainColor);
  }
  
  @media only screen and (max-width: 767px) {
    .error-page-inner .inner h1.title {
      font-size: 107px;
    }
  }
  
  .error-page-inner .inner .sub-title {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 50px;
    color: #232323;
  }
  
  @media only screen and (max-width: 767px) {
    .error-page-inner .inner .sub-title {
      font-size: 40px;
    }
  }
  
  .error-page-inner .inner span {
    font-size: 16px;
    margin-top: 10px;
    color: #454545;
    font-weight: 300;
    display: block;
    margin-bottom: 28px;
  }
  /*====================================================
--<	Team Single Section CSS -->
======================================================*/
  
.profile-section {
    position: relative;
  }
  .profile-section .content-part .title {
    color: #111111;
    font-weight: 600;
    line-height: 1.3em;
    font-size: 26px;
  }
  .profile-section .content-part p {
    font-size: 16px;
    margin: 0;
  }
  .profile-section .content-column {
    position: relative;
  }
  .profile-section .content-column .inner-column {
    position: relative;
  }
  .profile-section .content-column .inner-column h2 {
    position: relative;
    color: #111111;
    font-weight: 700;
    margin: 0;
  }
  .profile-section .content-column .inner-column h4 {
    position: relative;
    color: #111111;
    font-weight: 600;
    line-height: 1.3em;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .profile-section .content-column .inner-column h5 {
    position: relative;
    color: #111111;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.3em;
    margin-top: 12px;
    margin-bottom: 0px;
  }
  .profile-section .content-column .inner-column p {
    position: relative;
    color: #626262;
    font-weight: 400;
    line-height: 1.9em;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 35px;
  }
  .profile-section .content-column .inner-column .student-list {
    position: relative;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .profile-section .content-column .inner-column .student-list li {
    position: relative;
    color: #393939;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3em;
    margin-right: 80px;
    display: inline-block;
    list-style: disc;
    margin-bottom: 20px;
  }
  .profile-section .content-column .inner-column .student-list li .fa {
    color: var(--mainColor);
    margin: 0px 2px;
  }
  .profile-section .content-column .inner-column .student-list li:last-child {
    margin-right: 0px;
  }
  .profile-section .image-column {
    position: relative;
  }
  .profile-section .image-column .inner-column {
    position: relative;
    padding: 30px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  }
  .profile-section .image-column .inner-column .image {
    position: relative;
  }
  .profile-section .image-column .inner-column .image img {
    position: relative;
    display: inline-block;
    border-radius: 5px;
    width: 100%;
  }
  .profile-section .image-column .team-content h3 {
    position: relative;
    font-weight: 700;
    color: #111111;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .profile-section .image-column .team-content .text {
    position: relative;
    color: #454545;
    font-size: 18px;
    line-height: 1.7em;
    margin-bottom: 8px;
  }
  .profile-section .image-column .team-content .personal-info {
    margin-bottom: 20px;
  }
  .profile-section .image-column .team-content .personal-info li {
    margin: 5px 0;
    display: inline-block;
    padding-right: 15px;
  }
  
  .profile-section .image-column .team-content .personal-info li a {
    color: #111111;
  }
  .profile-section .image-column .team-content .personal-info li a:hover {
    color: var(--mainColor);
  }
  .profile-section .image-column .team-content .personal-info li span i:before {
    font-size: 16px;
    margin-right: 10px;
  }
  .profile-section .image-column .social-box {
    position: relative;
    text-align: center;
  }
  .profile-section .image-column .social-box a {
    position: relative;
    color: #626262;
    font-size: 28px;
    margin: 0px 5px;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
  }
  .profile-section .image-column .social-box a:hover {
    color: var(--mainColor);
  }
  .profile-section .browse-course-section {
    position: relative;
    margin-top: 50px;
  }
  .profile-section .browse-course-section .styled-pagination {
    margin-top: 20px;
  }
  .profile-section .skill-title {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .profile-section .rs-progress {
    height: 8px;
    border: 1px solid var(--mainColor);
    border-radius: 0;
    overflow: visible;
    padding: 1px;
    background: #fff;
    margin-top: 50px;
  }
  .profile-section .rs-progress + .rs-progress {
    margin-top: 50px;
  }
  .profile-section .rs-progress .progress-bar {
    position: relative;
    text-align: left;
    line-height: 4px;
    border-radius: 0;
    box-shadow: none;
    background-color: var(--mainColor);
    overflow: visible;
  }
  .profile-section .rs-progress .progress-bar .pb-label {
    position: absolute;
    left: 0px;
    top: -24px;
    color: #666;
    font-size: 15px;
    font-weight: 600;
  }
  .profile-section .rs-progress .progress-bar .pb-percent {
    position: absolute;
    right: -13px;
    font-weight: 500;
    color: #fff;
    font-size: 10px;
    top: -30px;
    background: var(--mainColor);
    padding: 8px 5px;
  }
  .profile-section .rs-progress .progress-bar .pb-percent:after {
    content: '';
    position: absolute;
    border: 5px solid transparent;
    left: 50%;
    border-top-color:var(--mainColor);
    top: 20px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 1366px) {
    .profile-section .content-column .student-list li {
        margin-right: 50px;
    }
} 
@media only screen and (max-width: 1199px) {
    .profile-section .content-column .student-list li {
        margin-right: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .profile-section .content-column h2 {
        font-size: 32px;
    }
    .profile-section .content-column h4 {
        font-size: 20px;
    }
    .profile-section .content-column h5 {
        font-size: 22px;
    }
}
@media only screen and (max-width: 575px) {
.profile-section .content-column h5 {
    font-size: 20px;
}
.profile-section .content-column .inner-column p {
    margin-top: 0;
}
.profile-section .content-column .student-list li {
    display: block;
    margin: 0 0 15px;
}
}

@media only screen and (max-width: 480px) {

.profile-section .image-column .inner-column {
    padding: 30px 25px;
}
.profile-section .content-column h2 {
    font-size: 30px;
    margin-bottom: 15px;
}
.profile-section .content-column h4 {
    font-size: 17px;
}
.profile-section .content-column .student-list {
    margin-bottom: 30px;
}
.profile-section .image-column .text {
    font-size: 16px;
    font-weight: 500;
}
}
/*====================================================
--<	About Area Css -->
======================================================*/
.about_icon {
    float: left;
}
.about_icon .icon {
    color: var(--mainColor);
    img{
        width: 40px;
    }
}
.singel-about-content {
    overflow: hidden;
}
.singel-about-content.boder {
    border-left: 1px solid #ddd;
}
.singel-about-content h5 {
    font-weight: 700;
    margin: 0;
}
.single_about_thumb_inner img {
    width: 100%;
}
.about_thumb img {
    width: 100%;
}
.about_skill_thumb img {
    width: 100%;
}
.skill-wrapper h6 {
    margin-bottom: 8px;
}
.pd_gap {
    background: #fff;
    padding: 18px 0 18px;
    border-radius: 8px;
}
.home11 .about_icon .icon {
    font-size: 20px;
    margin-top: -8px;
}
/* Team Experts Css */

.team_experts_text h1 {
    font-size: 136px;
    display: inline;
    font-weight: 800;
    color: var(--mainColor);
    float: left;
    line-height: 1;
    margin-top: -14px;
    margin-right: 15px;
}
.team_experts_title h2 {
    font-size: 36px;
    font-weight: 800;
    line-height: 1;
    color: #232323;
    padding-top: 11px;
    display: inline;
}
.team_experts_text p {
    font-size: 18px;
}
.experience .main_video {
    position: relative;
    margin-top: -285px;
}
 
@media (min-width: 768px) and (max-width: 1024px) {
    .team_experts_text h1 {
        font-size: 90px;
        display: inline;
        font-weight: 800;
        color: var(--mainColor);
        float: left;
        line-height: 1;
        margin-top: -14px;
        margin-right: 15px;
    }
    
    .team_experts_title h2 {
        font-size: 22px;
        font-weight: 800;
        line-height: 1;
        color: #232323;
        padding-top: 11px;
        display: inline;
    }
  }
  @media (min-width: 320px) and (max-width: 599px) {
    .team_experts_text h1 {
        font-size: 90px;
        display: inline;
        font-weight: 800;
        color: var(--mainColor);
        float: left;
        line-height: 1;
        margin-top: -14px;
        margin-right: 15px;
    }
    
    .team_experts_title h2 {
        font-size: 30px;
        font-weight: 800;
        line-height: 1;
        color: #232323;
        padding-top: 11px;
        display: inline;
    }
  }





/*====================================================
--<	Service Details Area Css -->
======================================================*/
.service-details-pn-list ul li {
    margin-bottom: 2px;
    transition: .5s;
    list-style: none;
}
.service-details-pn-list ul li a {
    position: relative;
    display: block;
    padding: 14px 15px 14px 18px;
    background-color: #F3F6F9;
    font-weight: 600;
    font-size: 17px;
    transition: .5s;
    color: #232323;
    font-family: var(--fontFamily);
}
.service-details-pn-list ul li a:hover {
    background: var(--mainColor) !important;
    color: #fff !important;
}
.service-details-pn-list ul li a i {
    float: right;
    font-size: 17px;
    font-weight: 600;
    position: relative;
    margin-top: 5px;
}
.service-details-big-button a {
    color: #ffffff;
    background: var(--mainColor);
    font-family: Open Sans;
    font-weight: 500;
    text-transform: capitalize;
    border: 0px solid #ffffff;
    border-radius: 5px;
    padding: 14px 35px 14px 35px;
    display: block;
}
.service-details-big-button a:hover {
    background: #00247e;
}

.details-big-content {
    text-align: center !important;
}
.service-details-big-button a span i {
    margin-left: 20px;
}
.service-details-pn-about{
	position:relative;
	z-index:1;
}
.service-details-pn-about::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: rgba(12,90,219,.85);
    z-index: -1;
}
.service-details-pn-about-content{
    text-align: center;
}
.service-main-details-content-title h3 {
    font-weight: 800;
}
.service-details-pn-about-content-title h4 {
    color: #fff;
	font-weight:700;
}
.service-details-pn-about-content-text p {
    color: #fff;
}
.service-details-pn-about-content-button a {
    display: inline-block;
    padding: 10px 30px;
    background: #fff;
    color: var(--mainColor);
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
}
/*-- Main Service Details --*/
.service-main-details-inner-thumb img {
    width: 100%;
}
.service-details-video-thumb img {
    width: 100%;
}
.service-details-video .single-video{
	position:relative;
}
.service-details-video .video-icon {
    position: absolute;
    left: 0;
    bottom: 265px;
    right: 0;
}
.service-details-research-button a {
    color: #ffffff;
    background: var(--mainColor);
    font-family: Open Sans;
    font-weight: 500;
    text-transform: capitalize;
    border: 0px solid #ffffff;
    border-radius: 5px 5px 5px 5px;
    padding: 14px 35px 14px 35px;
    margin-top: 15px;
	transition:.5s;
}
.service-details-research-button a:hover {
    background: #00247e;
}
.service-details-research-title h4 {
    font-weight: 800;
}

/* Team Member CSS */
.team-member
{
    background-color: #f4f6fc;
    background-size: cover;
}
.our-team{
    position: relative;
    margin: 45px 0;
   
}
.our-team img{
    width:100%;
    height: auto;
    z-index:1;
    transform:translateY(0px);
    transition: all 0.50s ease;
    height: 278px;
    object-fit: cover;
}
.our-team:hover img{
    transform:translateY(-60px);
}
.our-team .team-content{
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;
    opacity:0;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    background:var(--mainColor);
    transform:translateY(0px);
    transition: all 0.50s ease-in-out 0s;
}
.our-team:hover .team-content{
    transform:translateY(39px);
    opacity:1;
}
.our-team .team-title{
    font-size:23px;
    font-weight: 700;
    color:#fff;
    margin:0;
    
}
.our-team .team-title small{
    display: block;
    color:#fff;
    margin:1% 0 3% 0;
    font-size: 18px;
    transition: .5s;
}
.our-team .social-link{
    padding:0;
    margin:0;
    list-style:none;
    font-size: 19px;
}
.our-team .social-link li{
    display: inline-block;
    margin-right:2%;
}
.our-team .social-link li a{
    color:#fff;
    transition: all 0.50s ease;
}
.our-team .social-link li a:hover{
    color: #111a3a;
}
@media screen and (max-width: 767px){
    .our-team{
        margin-bottom: 15%;
    }
}



/*====================================================
--<	wrapper-coming-soon Css -->
======================================================*/
.wrapper-coming {
    position: absolute;
    background-image: url(../images/coming-soon.jpg);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #444;
    background-blend-mode: overlay;
  }
  
  .coming-soon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  
  .coming-soon h1 {
    display: inline-block;
    font-size: 49px;
    border: 5px solid #fff;
    padding: 15px 15px;
    color: #fff;
    animation: text-animation 3s ease-in-out forwards;
    margin: 9px;
  }
  
  .coming-soon .ctdown {
    font-weight: 700;
    font-size: 42px;
    display: flex;
    justify-content: center;
    min-height: 160px;
    opacity: 0;
    transition: opacity 3s ease-in-out;
    margin: 40px 9px 0px 9px;
  }
  
  .time-box {
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0, 0.7);
    width: 160px;
    height: 160px;
  }
  
  .time-box:not(:last-child) {
    margin-right: 20px;
  }
  
  .time-box span {
    font-size: 18px;
    font-weight: 200;
  }
  
  @keyframes text-animation {
    from {
      transform: scale(0) rotateY(0deg);
    }
    to {
      transform: scale(1) rotateY(720deg);
    }
  }











/*====================================================
--<	Digital Box Process Css -->
======================================================*/
  .digital-inner-box {
    background-color: #f4f8fc;
    padding-top: 80px;
    padding-bottom: 65px;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    z-index: 1;
    border-radius: 5px
}
.digital_box_process {
    background-color: #0064fb;
    padding: 35px 25px;
    max-width: 165px;
    margin: 0 auto 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    -webkit-transition: .6s;
    transition: .6s
}

.digital_box_process::before {
    content: '';
    position: absolute;
    right: -15px;
    bottom: -15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    -webkit-transition: .6s;
    transition: .6s;
    background-color: #fff
}

.digital_box_process::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    -webkit-transition: .6s;
    transition: .6s;
    background-color: #0064fb
}

.digital_box_process i {
    font-size: 35px;
    color: #fff
}

.digital_box_process p {
    font-size: 18px;
    color: #fff;
    margin-top: 5px
}

.digital_box_process:hover {
    -webkit-animation: bounce 1s;
    animation: bounce 1s
}

.digital_box_process-box {
    background-color: #0064fb;
    padding: 35px 25px;
    margin-bottom: 30px;
    text-align: center;
    -webkit-transition: .6s;
    transition: .6s;
    border-radius: 5px
}

.digital_box_process-box i {
    font-size: 45px;
    color: #fff
}

.digital_box_process-box p {
    font-size: 20px;
    color: #fff;
    margin-top: 5px
}


.digital-process-bar-shape {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center
}
/*====================================================
--<	Digital Tool Box Css -->
======================================================*/
.digital_tool_box{
	margin-bottom:20px;
}
.digital_tool_box_content {
    background: #eff2f7;
    padding: 8px 15px 20px 17px;
    position: relative;
    z-index: 1;
    border-radius: 3px;
}
.digital_tool_box_content::before {
    position: absolute;
    left: 0;
    content: "";
    bottom: 0;
    height: 0%;
    width: 100%;
    background: var(--mainColor);
    z-index: -1;
	transition:.5s;
	border-radius:3px;
}
.digital_tool_box:hover .digital_tool_box_content::before{
	height: 100%;
}
.digital_tool_box_content h5 {
    font-size: 17px;
}
.digital_tool_box:hover .digital_tool_box_content h5{
	color:#fff;
}

/*====================================================
--<	Service Demobox Card Css -->
======================================================*/

.service_demobox_card {
    box-shadow:0 0 15px rgba(51,51,51,0.1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	transition:.5s;
}
.service_demobox_card_icon {
    float: left;
}
.service_demobox_card_icon i {
    color: var(--mainColor);
    font-size: 40px;
    margin-top: 14px;
}
.service_demobox_card_content {
    overflow: hidden;
}
.service_demobox_card_title h4 {
    font-weight: 700;
}
.service_demobox_card:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}





/*====================================================
--<	Career Listing Css -->
======================================================*/
.terms h2 {
	position: relative;
    font-size: 38px;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.terms h2:after {
	content: '';
    position: absolute;
    top: 100%;
    margin-top: 10px;
    left: 0;
    width: 100px;
    border-top: 4px solid #232323;
}

.terms p {
	margin-bottom:25px;
}

.careers-listing {
	list-style: none;
	margin-bottom: 60px;
	margin-top: 60px;
	padding-left:0;
}
.careers-listing h5 {
	font-size:20px;
}
.careers-listing li {
	padding: 25px 0;
	border:none;
	border-bottom: 2px solid #e8e8e8;
	list-style:none;
}

.career-location {
	border-left: solid 1px #ccc;
	padding-left: 13px;
}
.career-type {
	margin-right:10px;
}
.career-details {
	text-align: right;
	padding-top:10px;
}
.career-category {
	padding-top:14px;
}
/* Career Work */
.career_work_thumb img {
    width: 100%;
}
.career_work_icon .icon {
    height: 70px;
    width: 70px;
    background: var(--mainColor);
    line-height: 68px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 35px;
    float: left;
    margin-right: 20px;
	border:1px solid var(--mainColor);
	transition:.5s;
}
.career_work_content:hover .career_work_icon .icon{
	background:transparent;
	color:var(--mainColor);
	
	
}

/*====================================================
--<	Case Study Box-card Css -->
======================================================*/
.box{
    
    text-align: center;
    overflow: hidden;
    perspective: 800px;
    position: relative;
    margin: 0px 0px 35px;
}
.box:before,
.box:after{
    content: "";
    background: rgba(255,255,255,0.9);
    width: 44%;
    height: 44%;
    opacity: 0;
    transform: translateY(-50%) rotateY(90deg);
    transform-origin: right center;
    transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    left: 5.1%;
    z-index: 1;
    transition: all 0.4s ease-out;
}
.box:after{
    transform: translateY(-50%) rotateY(-90deg);
    transform-origin: left center;
    left: auto;
    right: 7%;
}
.box:hover:before,
.box:hover:after{
    opacity: 1;
    transform: translateY(-50%) rotateY(0);
}
.box img{
    width: 100%;
    height: 440px;
    object-fit: cover;
    transition: all 0.4s ease;
}
.box .box-content{
    color: var(--mainColor);
    width: 100%;
    opacity: 0;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    transition: all 0.4s;
}
.box:hover .box-content{ opacity: 1; }
.box .title3{
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 3px 0;
   
}
.box .post3{ font-size: 16px; }
.box .icon{
    padding: 0;
    margin: 0;
    list-style: none;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: 13px;
}
.box .icon li{
    display: inline-block;
    margin: 0 7px;
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.3s;
}
.box:hover .icon li{
    opacity: 1;
    transform: translateY(0);
}
.box:hover .icon li:nth-child(2){ transition: all 0.3s ease 0.2s; }
.box .icon li a{
    color: #fff;
    background: var(--mainColor);
    font-size: 22px;
    line-height: 50px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: 0 0 0 0px var(--mainColor);
    display: block;
    transition: all 0.5s ease;
}
.box .icon li  a:hover{
    color: #fff;
    border-radius: 10%;
}
@media only screen and (max-width:990px){
    .box { margin: 0 0 30px; }
}

/*====================================================
--<	Portfolio Area Css -->
======================================================*/

/* Portfolio Nav */
.portfolio_nav {
    margin-bottom: 50px;
}
.portfolio_menu ul {
    text-align: center;
    list-style: none;
}
.portfolio_menu ul li {
    display: inline-block;
    margin: 0 14px;
    cursor: pointer;
}
.portfolio_menu ul li a{
    display: block;
    color:#fff;
	text-transform:uppercase;
	position:relative;
	transition:.5s;
}
.portfolio_menu ul li a:before {
    position: absolute;
    content: "";
    left: 0;
    top: 24px;
    height: 2px;
    width: 0%;
    background: #fff;
	transition:.5s;
}
.portfolio_nav ul li {
    background: #fff;
    border: medium none;
    font-size: 16px;
    line-height: 1;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: capitalize;
    padding: 12px 27px;
    font-weight: 500;
    letter-spacing: 2px;
    position: relative;
    margin: 0 3px;
    margin-bottom: 15px;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid var(--mainColor);
}
.portfolio_nav ul li:hover, .portfolio_nav ul li.current_menu_item {
    color: #fff;
    background: var(--mainColor);
}

/* Single Portfolio */
.single_portfolio {
    overflow: hidden;
    margin-bottom: 30px;
	position:relative;
	transition:.5s;
}
.single_portfolio_thumb {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
	transition:.5s;
}
.single_portfolio_content {
    position: absolute;
    text-align: center;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    right: 20px;
    bottom: 20px;
    left: 20px;
    opacity: 0;
    background: var(--mainColor);
    margin: auto;
    text-align: left;
    padding: 30px 20px 30px;
    border-radius: 5px;
}
.single_portfolio_icon {
    text-align: center;
    padding-bottom: 10px;
}
.single_portfolio_content_inner {
    text-align: center;
}
.single_portfolio_content_inner h2 {
    font-size: 22px;
    margin: 0;
}
.single_portfolio_content_inner h2 a {
    display: inline-block;
    overflow: hidden;
    font-weight: 600;
    color: #fff;
}
.single_portfolio_icon i {
    background: #fff;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    border-radius: 5px;
    color: #135ef3;
	transition:.5s;
}
.single_portfolio_thumb img {
    width: 100%;
}
.single_portfolio_content_inner span {
    color: #fff;
}
/* Portfolio Hover */
.single_portfolio:hover .single_portfolio_thumb {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
.portfolio_menu ul li a:hover:before {
    width: 100%;
}
.single_portfolio:hover .single_portfolio_content{
	opacity: 1;
	bottom:35px;
}
.single_portfolio_icon i:hover{
	background: #fff;
	color:#135ef3;
}

/* How It Work Style Tow */
.it_work_style_two .whychoose_flipbox .whychoose_flipbox_font, .it_work_style_two .whychoose_flipbox .whychoose_flipbox_back {
    min-height: 410px;
}
.it_work_style_two .it_work_style_two .whychoose_flipbox .whychoose_flipbox_inner {
    top: 50%;
}
.whychoose_flipbox_font .icon {
    color: #fff;
    line-height: 80px;
    height: 80px;
    width: 80px;
    background: var(--mainColor);
    border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 30px;
    }
}

/* Testimonial Style Two */
.single_testimonial_two {
    box-shadow: 0px 0px 27px 0px rgba(0,0,0,.17);
    text-align: center;
    background: #fff;
    padding: 40px 20px 30px;
}
.single_testimonial_thumb_two img {
    border-radius: 50%;
    margin: auto;
}
.single_testimonial_content_title_two h4 {
    font-size: 22px;
}
.single_testimonial_content_title_two span {
    color: var(--mainColor);
}
@keyframes checkAnim {
 0%,
 100% {
  box-shadow:0 0 0 5px rgba(12,90,219, 0.8)
 }
 50% {
  box-shadow:0 0 0 8px rgba(12,90,219, 0.8)
 }
}
/* Pricing Table CSS */

.pricingTable{
    background: #fff;
    font-family: 'Barlow', sans-serif;
    text-align: center;
    padding: 0 0 30px;
    margin: 0 10px;
    border: 10px solid var(--mainColor);
    border-radius: 100px 100px 0 0;
}
.pricingTable .pricingTable-header{
    color: var(--mainColor);
    background: var(--mainColor);
    border-radius: 80px 80px 0 0;
    margin: 0 0 30px;
}
.pricingTable .price-value{
    background: #fff;
    text-align: center;
    height: 150px;
    width: 150px;
    padding: 32px 10px;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.6);
}
.pricingTable .price-value .currency{
    font-size: 40px;
    font-weight: 600;
    vertical-align: bottom;
    display: inline-block;
}
.pricingTable .price-value .amount{
    font-size: 60px;
    font-weight: 700;
    line-height: 62px;
    display: inline-block;
}
.pricingTable .price-value .duration{
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: -3px;
    display: block;
}
.pricingTable .title{
    background: #fff;
    color:var(--mainColor) ;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 15px 0;
    margin: 11px -15px 0;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    position: relative;
}
.pricingTable .title:before,
.pricingTable .title:after{
    content: "";
    background:linear-gradient(to top right,transparent 49%,var(--mainColor) 50%);
    width: 5px;
    height: 5px;
    opacity: 0.5;
    position: absolute;
    bottom: -5px;
    left: 0;
}
.pricingTable .title:after{
    transform: rotateY(180deg);
    left: auto;
    right: 0;
}
.pricingTable ul{
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
    display: inline-block;
}
.pricingTable ul li{
    color: var(--mainColor);
    font-size: 18px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0 0 15px;
    position: relative;
}
.pricingTable ul li:last-child{ margin-bottom: 0; }
.pricingTable ul li:before{
    content: "";
    width: 8px;
    height: 8px;
    background: var(--mainColor);
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 0;
}

@media only screen and (max-width: 990px){
    .pricingTable{ margin-bottom: 30px; }
}


/*====================================================
--<	Process Area Css -->
======================================================*/

.single_process {
    text-align: center;
	position:relative;
	transition:.5s;
	margin-bottom:30px;
}
.single_process_thumb {
    display: inline-block;
    border-radius: 0%;
    animation: checkAnim 2s infinite;
    height: 240px;
    width: 230px;
    line-height: 230px;
    margin-top: 30px;
}

.single_process_thumb img {
    border-radius:0% ;
    border: 10px solid #fff;
    width: 100%;
}
.single_process_number {
    position: relative;
}
.single_process_number_inner {
    position: absolute;
    left: 0;
    bottom: -25px;
    margin: auto;
    transition: .5s;
    content: "";
    right: 0;
}
.single_process_number span {
    height: 50px;
    width: 50px;
    background: var(--mainColor);
    display: inline-block;
    line-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
}
.single_process_content {
    padding-top: 30px;
}
.single_process_content h2 {
    font-size: 22px;
    font-weight: 700;
}
.single_process:hover .single_process_number_inner {
    bottom:50px;
}


/*====================================================
--<	Counter Area Css -->
======================================================*/
.cntr_bg_up{
	background: #ffffff;
    border-radius: 15px;
    box-shadow: 11px 11px 38px rgba(0,0,0,0.2);
}
.countr_text h1 {
    font-size: 40px;
    font-weight: 800;
}
.counter_desc h5 {
    margin-top: 6px;
	color:var(--mainColor);
}
.single_counter_icon i {
    color: #fff;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    display: inline-block;
    width: 80px;
    height: 80px;
    background: var(--mainColor);
    border-radius: 100%;
    line-height: 80px;
    img{
        width: 30px;
    }
}
.white .countr_text h1 {
    color: #fff;
}
.white .counter_desc h5 {
    color: #fff;
}
.border_bottom_lin {
    position: relative;
}
.border_bottom_lin::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    height: 90px;
    width: 3px;
    background: var(--mainColor);
    content: "";
    margin: auto;
}
.single_counter_content {
    overflow: hidden;
}
.single_counter_icon_two .icon {
    float: left;
    margin-right: 20px;
    margin-top: 12px;
}
.single_counter_icon_two .icon i {
    font-size: 35px;
    background: var(--mainColor);
    height: 70px;
    width: 70px;
    line-height: 70px;
    display: inline-block;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}

/*====================================================
--< Testimonial Area Css -->
======================================================*/

.testimonial .testimonial-content{
    margin: 20px 0 50px 0;
    position: relative;
}
.testimonial .testimonial-content:after{
    content: "";
    width: 20px;
    height: 20px;
    border-top: 20px solid #fff;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    margin: 0 auto;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
}
.testimonial p{
    padding: 25px 22px;
    margin: 0;
    background: #fff;
    border-radius: 15px;
    font-size: 16px;
    line-height: 27px;
    font-family: var(--fontFamily);
    color: #616161;
    position: relative;
}
.testimonial p:before,
.testimonial p:after{
   
    font-weight: 900;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 8px;
    text-align: center;
    background: #fff;
    position: absolute;
}

.testimonial .testimonial-profile{
    display: flex;
    padding: 1px;
    border: 1p5x solid #fff;
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
}
.testimonial .pic{
    float: left;
    overflow: hidden;
    img{
        width: 80px;
        height: 80px;
        border: 1px solid #fff;
        border-radius: 50%;
        object-fit: cover;
    }
}
.testimonial .title1{
    display: inline-block;
    margin: 0 0 0 30px;
    font-size: 19px;
    font-weight: bold;
    color: #fff;
    position: relative;
    //top: 22px;
}
.testimonial .title1:before{
    content: "";
    width: 1px;
    height: 70px;
    background: #fff;
    position: absolute;
    top: 0;
    left: -15px;
}
.testimonial .post1{
    display: block;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
}
.owl-theme .owl-controls{
    margin-top: 30px;
}
.owl-theme .owl-controls .owl-page span{
    background: transparent;
    border: 1px solid #fff;
    opacity: 1;
}
.owl-theme .owl-controls .owl-page.active span{
    background: #dca842;
    border: 1px solid #dca842;
}
@media only screen and (max-width: 479px){
    .testimonial .description{
        padding: 20px;
        font-size: 16px;
    }
    .testimonial .testimonial-profile{ padding: 10px; }
    .testimonial .title1{ font-size: 16px; }
    .testimonial .post1{ font-size: 13px; }
}

/*====================================================
--<	Testimonial Element Area Css -->
======================================================*/

.testimonial_element .testimonial-content{
    margin: 50px 0 50px 0;
    position: relative;
}
.testimonial_element .testimonial-content:after{
    content: "";
    width: 20px;
    height: 20px;
    border-top: 20px solid #fff;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    margin: 0 auto;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
}
.testimonial_element .description1{
    padding: 25px 22px;
    margin: 0;
    background: #fff;
    border-radius: 15px;
    font-size: 16px;
    line-height: 27px;
    font-family: var(--fontFamily);
    color: #616161;
    position: relative;
    box-shadow: 0 18px 17px 0 rgba(0,0,0,.1);
}
.testimonial_element .description1:before,
.testimonial_element .description1:after{
    font-weight: 900;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 8px;
    text-align: center;
    background: #fff;
    position: absolute;
}

.testimonial_element .testimonial-profile{
    display: table;
    padding: 15px;
    border: 0px solid #fff;
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 18px 17px 0 rgba(0,0,0,.1);
    background-color: #fff;
}
.testimonial_element .pic{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #fff;
    float: left;
    overflow: hidden;
}
.testimonial_element .title1{
    display: inline-block;
    margin: 0 0 0 30px;
    font-size: 19px;
    font-weight: 700;
    position: relative;
    top: 22px;
}
.testimonial_element .title1:before{
    content: "";
    width: 1px;
    height: 70px;
    background: #fff;
    position: absolute;
    top: -8px;
    left: -15px;
}
.testimonial_element .post1{
    display: block;
    font-size: 14px;
    margin-top: 10px;
}

@media only screen and (max-width: 479px){
    .testimonial_element .description{
        padding: 20px;
        font-size: 16px;
    }
    .testimonial_element .testimonial-profile{ padding: 10px; }
    .testimonial_element .title1{ font-size: 16px; }
    .testimonial_element .post1{ font-size: 13px; }
}






/*====================================================
--<  FAQ Area Css -->
======================================================*/
.faq-title {
    font-size: 2em;
    margin: 0.4em 0;
}
.faq-list > div {
    border-bottom: 0.07em solid #ededed;
    padding: 1em 0em;
} 

.faq-list > div:last-child {
    border: initial;
}

summary {
    font-size: 1.4em;
    font-weight: bold;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
    color:var(--mainColor);
}

summary:hover {
    color: #00247e;
    transition: all 0.3s ease;
}

details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 55px}
}

details[open] summary {
  color: var(--mainColor);
}

details[open] p {
    border-left: 2px solid var(--mainColor);
    margin-left: 55px;
    padding-left: 25px;
    opacity: 100;
    transition: all 3s ease;
}

details[open] summary:after {
    content: "-";
    font-size: 3.2em;
    margin: -0.5em 0.14em 0 0;
    font-weight: 200;
}

summary::-webkit-details-marker {
    display: none;
}

summary:after {
    background: transparent;
    border-radius: 0.3em;
    content: "+";
    color: var(--mainColor);
    float: left;
    font-size: 1.8em;
    font-weight: bold;
    margin: -0.3em 0.25em 0 0;
    padding: 0;
    text-align: center;
    width: 25px;
}


/*====================================================
--<  FAQ Accordian Css -->
======================================================*/
#faq__accordian-main-wrapper {
	
	margin: 0 auto;

  line-height: 1.6;
}
.faq__accordion-content p {
	margin: 0;
}
.faq__accordian-main-wrapper{
    img{
        width: 100%;
    }
}
.faq__accordian-heading {
	background-color: var(--mainColor);
	padding: 10px 30px 10px 45px;
	display: block;
	margin-bottom: 15px;
	position: relative;
	font-weight: 500;
	text-decoration: navajowhite;
	color: #FFF;
    border: none;
    outline: none;
    width: 100%;
    text-align: start;
}
.faq__accordian-heading:hover
{
    background-color:#00247e;
    color: #fff;
}
.faq__accordian-heading::before {
	content: "\f138";
   
	color: #FFF;
	font-family: fontawesome;
	position: absolute;
	left: 20px;
	top: 50%;
	-webkit-transition: 0.3s linear all;
	-moz-transition: 0.3s linear all;
	-ms-transition: 0.3s linear all;
	-o-transition: 0.3s linear all;
	transition: 0.3s linear all;
	-webkit-transform: translateY(-50%) rotate(0deg);
	-moz-transform: translateY(-50%) rotate(0deg);
	-ms-transform: translateY(-50%) rotate(0deg);
	-o-transform: translateY(-50%) rotate(0deg);
	transform: translateY(-50%) rotate(0deg);
}
.faq__accordian-heading.active {
	background-color: var(--mainColor);
	color: #ffffff;
}
.faq__accordian-heading.active::before {
	-webkit-transition: 0.3s linear all;
	-moz-transition: 0.3s linear all;
	-ms-transition: 0.3s linear all;
	-o-transition: 0.3s linear all;
	transition: 0.3s linear all;
	-webkit-transform: translateY(-50%) rotate(360deg);
	-moz-transform: translateY(-50%) rotate(360deg);
	-ms-transform: translateY(-50%) rotate(360deg);
	-o-transform: translateY(-50%) rotate(360deg);
	transform: translateY(-50%) rotate(360deg);
}
.faq__accordian-heading.active::before {
	color: #ffffff;
}
.faq__accordion-content {
	padding: 10px 20px;
}
.single_brand {
    margin-left: 25px;
}

/*====================================================
--<	 Contact Area Css -->
======================================================*/
.form_box input {
    width: 100%;
    height: 50px;
    padding-left: 15px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
	transition:.5s;
}

.form_box textarea {
    width: 100%;
    background: transparent;
    padding-left: 15px;
    padding-top: 20px;
    height: 250px;
    border: 1px solid #fff;
    border-radius: 5px;
	color: #fff;
	transition:.5s;
}
.quote_btn button {
    background: #fff;
    border: 0;
    display: inline-block;
    text-align: center;
    padding: 14px 39px;
    border-radius: 7px;
    transition: .3s;
}
.quote_btn button:hover {
    background: #00247e;
    color:#fff;
}
.appointment_form_inner{
	background: #ffffff;
    padding: 70px 70px 70px 70px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 5px 30px rgba(0,0,0,0.15);
} 
.appointment_title h1 {
    text-align: center;
    font-weight: 800;
}
.appointment_form .form_box input {
    border: 1px solid #e6e6e6;
    color: #495057;
}
.appointment_form .form_box textarea {
    border: 1px solid #e6e6e6;
	color: #495057;
}
.appointment_form .quote_btn button {
    background: var(--mainColor);
	color:#fff;
}
.appointment_form  .form_box input:focus,
.appointment_form  .form_box textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.main_contact_area .form_box input {
    border: 1px solid #e6e6e6;
    color: #495057;
	background-color: #fff;
}
.main_contact_area .form_box textarea {
    border: 1px solid #e6e6e6;
	color: #495057;
	background-color: #fff;
}
.main_contact_area .quote_btn button {
    background: var(--mainColor);
	color:#fff;
}
.main_contact_area  .form_box input:focus,
.main_contact_area  .form_box textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.map {
    width: 100%;
    margin: 0;
    height: 410px;
    border: 1px solid #ddd;
    border-radius: 10px;
}
.google_map_area .col-lg-12{
    padding: 0;
}

/*====================================================
--<	Contact Box Card1 Css -->
======================================================*/
.contact_box_card1{
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    text-align: center;
    padding: 35px 30px 10px;
    box-shadow: 0 10px 29.7px 0.3px rgba(225,225,225,.64);
    position: relative;
	background:#fff;
}
.contact_box_card1::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background-image: url(../images/);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index:1;
}
.contact_box_card1_cion i {
    color: var(--mainColor);
    background-color: #eff2f7;
    border-radius: 0 40px 40px;
    font-size: 30px;
    line-height: 70px;
	height:70px;
	width:70px;
	transition:.5s;
}
.contact_box_card1_title h4 {
    font-size: 22px;
    font-weight: 700;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.contact_box_card1_text p{
	transition:.5s;
}
.contact_box_card1:hover{
	background:var(--mainColor);
	transform:translateY(-15px);
}
.contact_box_card1:hover .contact_box_card1_title h4,
.contact_box_card1:hover .contact_box_card1_text p{
	color:#fff;
}

.cnt_box {
    background: #fff;
    box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.2);
}
.contact_sm_area .form_box input {
    border: 1px solid #ddd;
}
.contact_sm_area .form_box textarea {
    border: 1px solid #ddd;
	height: 180px;
}
.contact_sm_area .quote_btn button {
    background: var(--mainColor);
    color: #fff;
}
.contact_sm_area .form_box input:focus,
.contact_sm_area .form_box textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.single_contact_abs_thumb img {
    width: 100%;
}
.app .form_box textarea {
    height: 170px;
}

/*====================================================
--<	Blog Area Css -->
======================================================*/
.post-slide{
   
    border-left: 8px solid var(--mainColor);
    border-bottom: 8px solid var(--mainColor);
    background-color: var(--whiteColor);
    -webkit-box-shadow: 3px 5px 25px 0 rgba(0, 0, 0 , 0.10);
    box-shadow: 3px 5px 25px 0 rgba(0, 0, 0 , 0.10);
    margin: 0px 0px 45px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}
.post-slide .post-img{
    position: relative;
    overflow: hidden;
}
.post-slide .post-img:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0,0,0.0);
    transition: all 0.40s linear 0s;
}
.post-slide:hover .post-img:before{
    background: rgba(0, 0,0,0.6);
}
.post-slide .post-img img{
    width: 100%;
    height: auto;
}
.post-slide .post-info{
    width: 75%;
    position: absolute;
    bottom: -100%;
    left: 12.5%;
    background: var(--mainColor);
    text-align: center;
    line-height: 26px;
    padding: 15px;
    transition: bottom 0.40s ease-in-out 0s;
}
.post-slide:hover .post-info{
    bottom:0;
}
.post-slide .category{
    padding: 0;
    margin: 0;
    list-style: none;
}
.post-slide .category li,
.post-slide .post-date{
    display: inline-block;
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
}
.post-slide .category li:after{
    content: " /";
}
.post-slide .category li:last-child:after{
    content: "";
}
.post-slide .category li a{
    color: #fff;
    transition: all 0.40s linear;
}
.post-slide .category li a:hover{
    color: #fff;
    text-decoration: none;
}
.post-slide .post-review{
    padding: 35px 20px 25px;
    background: #fff;
    position: relative;
}
.post-slide .icons{
    width: 90px;
    height: 90px;
    border: 4px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    right: 10px;
    overflow: hidden;
}
.post-slide .icons img{
    width: 100%;
    height: auto;
}
.post-slide .post-title{
    margin: 0 0 15px 0;
}
.post-slide .post-title a{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #232323;
    display: inline-block;
    
    transition: all 0.30s linear 0s;
}
.post-slide .post-title a:hover{
    text-decoration: none;
    color: var(--mainColor);
}
.post-slide .post-description{
    color: #616161;
    margin-bottom: 20px;
}
.post-slide .read{
    font-size: 13px;
    color: #555;
    display: block;
    text-align: right;
    text-transform: uppercase;
}
.post-slide .read:hover{
    text-decoration:none;
    color: var(--mainColor);
}
.blog_details a {
    color: #616161;
	transition:.3s;
	position:relative;
}
.blog_details a:before {
    position: absolute;
    right: -6px;
    height: 14px;
    width: 1px;
    background: #666;
    content: "";
    top: 5px;
}
.blog_details a:hover{
	color:var(--mainColor);
}

/*====================================================
--<	Blog-post1 Area Css -->
======================================================*/
.post-slide1{
   
    border-left: 0px solid #fff;
    border-bottom: 0px solid #fff;
    background-color: var(--whiteColor);
    -webkit-box-shadow: 3px 5px 25px 0 rgba(0, 0, 0 , 0.10);
    box-shadow: 0 0 5px rgba(0,0,0,0.10);
    margin: 0px 0px 45px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}
.post-slide1 .post-img{
    position: relative;
    overflow: hidden;
}
.post-slide1 .post-img:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0,0,0.0);
    transition: all 0.40s linear 0s;
}
.post-slide1:hover .post-img:before{
    background: rgba(0, 0,0,0.6);
}
.post-slide1 .post-img img{
    width: 100%;
    height: auto;
}
.post-slide1 .post-info{
    width: 75%;
    position: absolute;
    bottom: -100%;
    left: 12.5%;
    background: var(--mainColor);
    text-align: center;
    line-height: 26px;
    padding: 15px;
    transition: bottom 0.40s ease-in-out 0s;
}
.post-slide1:hover .post-info{
    bottom:0;
}
.post-slide1 .category{
    padding: 0;
    margin: 0;
    list-style: none;
}
.post-slide1 .category li,
.post-slide1 .post-date{
    display: inline-block;
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
}
.post-slide1 .category li:after{
    content: " /";
}
.post-slide1 .category li:last-child:after{
    content: "";
}
.post-slide1 .category li a{
    color: #fff;
    transition: all 0.40s linear;
}
.post-slide1 .category li a:hover{
    color: #fff;
    text-decoration: none;
}
.post-slide1 .post-review{
    padding: 35px 20px 25px;
    background: #fff;
    position: relative;
}
.post-slide1 .icons{
    width: 90px;
    height: 90px;
    border: 4px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    right: 10px;
    overflow: hidden;
}
.post-slide1 .icons img{
    width: 100%;
    height: auto;
}
.post-slide1 .post-title{
    margin: 0 0 15px 0;
}
.post-slide1 .post-title a{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #232323;
    display: inline-block;
    
    transition: all 0.30s linear 0s;
}
.post-slide1 .post-title a:hover{
    text-decoration: none;
    color: var(--mainColor);
}
.post-slide1 .post-description{
    color: #616161;
    margin-bottom: 20px;
}
.post-slide1 .read{
    font-size: 13px;
    color: #555;
    display: block;
    text-align: right;
    text-transform: uppercase;
}
.post-slide1 .read:hover{
    text-decoration:none;
    color: var(--mainColor);
}
.blog_details a {
    color: #616161;
	transition:.3s;
	position:relative;
}
.blog_details a:before {
    position: absolute;
    right: 46px;
    height: 14px;
    width: 1px;
    background: #fff;
    content: "";
    top: 5px;
}
.blog_details a:hover{
	color:var(--mainColor);
}

/* Newsletter Area CSS Style*/
.newsletter-bg{
    background-image: url(../images/bg-banner3.png);
    background-position: center;
    background-size: cover;
    background-color: #0060ff;
}
.newsletter_content_title h2 {
    font-size: 32px;
    font-weight: 700;
}
.newsletter_form input {
    font-size: 14px;
    height: 65px;
    padding: 5px 25px;
    position: relative;
    width: 100%;
    background: #F6F6FC;
    border: 0;
    border-radius: 0;
    margin-bottom: 14px;
    margin: auto;
    border: 0;
    border-radius: 15px;
}
.newsletter_form_send button {
    text-align: center;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    border: medium none;
    border-radius: 15px;
    background: var(--mainColor);
    display: block;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    margin: auto;
    padding: 15px 43px;
    position: absolute;
    right: 20px;
    top: 74%;
    display: inline-block;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.newsletter_form_send button:hover{
	color: #fff;
}


/*====================================================
--<	Footer Top Area Css -->
======================================================*/

.footer-toparea {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    
}
.widget-title {
    color: #fff;
    font-size: 22px;
}
.company-info-demo p {
    color: #fff;
}
.menu-quick-link-container ul li a {
    color: #fff;
	position:relative;
	transition:.5s;
}
.menu-quick-link-container ul li a:before {
    position: absolute;
    content: "";
    left: -24px;
    top: 10px;
    height: 1px;
    width: 13px;
    background: #fff;
}
.menu-quick-link-container ul li {
    padding-bottom: 10px;
    list-style: none;
}
.popular-post-text a {
    color: #fff;
	font-weight:500;
	transition:.5s;
}
.popular-post-image {
    float: left;
    img{
        width: 80px;
        height: 80px;
        object-fit: cover;
    }
}
.social-company-text a {
    font-size: 16px;
    transition: .5s;
}
.social-company-text a p {
    color:#fff;
	transition:.5s;
}
.social-company-text {
    float: left;
    color: #fff;
}
.social-company-icon a {
    font-size: 16px;
    color: #fff;
    margin: 0 5px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
}

.footer-social-info p span {
    font-weight: 600 !important;
    color: #fff;
}
.footer-social-info p {
    color: #fff;
}
.popular-post-text span {
    color: #adb0be;
}
.popular-post-item.active {
    border-bottom: 1px solid #adb0be;
}
.social-company-icon a:hover{
	color:var(--mainColor);
}
.social-company-text a p:hover {
    color:var(--mainColor);
}
.menu-quick-link-container ul li a:hover{
	color:var(--mainColor);
}
.popular-post-text a:hover{
	color:var(--mainColor);
}


/*====================================================
--<	footer-copyright Css -->
======================================================*/
.footer-copyright {
    border-top: 1px solid #fff;
}
.footer-copyright-content-copy p {
    color: #fff;
}
.footer-copyright-right-text {
	text-align: right;
}
.footer-copyright-right-text a {
	color: #fff;
	display: inline-block;
}
.footer-copyright-right-text .privacy-text {
	margin-right: 30px;
	position: relative;
}
.footer-copyright-right-text .privacy-text::before {
	position: absolute;
	content: "";
	right: -17px;
	top: 6px;
	height: 14px;
	width: 1px;
	background: #fff;
	transform: skew(-20deg);
}

/*====================================================
--<	Blog Details Area Css -->
======================================================*/
.blog-details-area {
    background: #fff;
}
.blog_dtl_top_bs span {
	background: var(--mainColor);
	padding: 8px 30px;
	color: #fff;
	border-radius: 30px;
}

/* Blog Details  Content CSS */
.blog_dtl_content {
    padding: 38px 45px 45px;
    -webkit-box-shadow: 0 3px 19px rgba(0,0,0,.08);
    box-shadow: 0 3px 19px rgba(0,0,0,.08);
    border-radius: 0 0 10px 10px;
}
.recent_post_dtls {
    padding: 38px 45px 45px;
    -webkit-box-shadow: 0 3px 19px rgba(0,0,0,.08);
    box-shadow: 0 3px 19px rgba(0,0,0,.08);
    border-radius: 0 0 10px 10px;
}

.blog_dt_title h4 {
    font-weight: 700;
}
.blog_dtl_thumb img {
    width: 100%;
}
.blog_dtl_content h2 {
    color: #0d1218;
    font-size: 35px;
    padding-top: 20px;
    font-weight: 700;
}
.blog-meta-left {
    padding: 12px 0 35px;
}
.blog-meta-left span {
    color: #0d1218;
    margin-right: 40px;
}
.blog-meta-left i {
    margin-right: 10px;
}
.blog_details_dtn_icon  button{
    margin-right: 4px;
}
.blog_details_dtn_icon a i:hover {
    background: var(--mainColor);
    border-color: var(--mainColor);
    color: #fff;
}
.blog_dtl_content h3 {
    color: #0d1218;
    padding: 10px 0 20px;
    font-size: 24px;
    font-weight: 700;
}
.blog_details blockquote {
    margin: 37px 0 36px;
    font-size: 14px;
    border-left: 7px solid var(--mainColor);
    background: #f7fafd;
    font-style: italic;
    font-weight: 500;
    padding: 25px 35px 15px;
}
.signatures {
	text-align: right;
	font-weight: 600;
	font-style: italic;
	font-size: 15px;
}
/* POST REPLY CSS */
.blog_comments_section {
    padding: 38px 45px 45px;
    -webkit-box-shadow: 0 3px 19px rgba(0,0,0,.08);
    box-shadow: 0 3px 19px rgba(0,0,0,.08);
    border-radius: 0 0 10px 10px;
}
.comments_ttl>h3 {
    font-size: 24px;
    color: #0d1218;
    font-weight: 700;
}
.comments_ttl {
	margin-bottom: 37px;
}
.commentst_content p {
    color: #0d1218;
}
.comments_thumb {
	float: left;
	margin-right: 20px;
	overflow: hidden;
}
.commentst_content {
	overflow: hidden;
}
.blog_comments_section .post_meta {
	margin-bottom: 6px;
}
.blog_comments_section .post_meta span {
	font-weight: 400;
	padding-right: 15px;
	color: #6d6d6d;
}
.blog_comments_section .post_meta span:hover {
	color: var(--mainColor);
}
.blog_comments_section .post_meta span:last-child:before {
	display: none;
}
.commentst_meta_reply {
	float: right;
}
.commentst_meta_reply i {
	margin-right: 10px;
}
.commentst_meta_reply:hover {
	color: #03a9f4;
}
.single_commentst_inner {
	margin-bottom: 44px;
	margin-top: 46px;
	padding-left: 63px;
}
.badmin i {
	margin-right: 3px;
}

/* COMMENT FORM CSS */
.blog_reply {
    overflow: hidden;
    margin-top: 50px;
    padding: 20px 15px 37px;
    box-shadow: 0 7px 16px rgba(0, 0, 0, 0.15);
}
.reply_ttl>h3 {
    font-size: 24px;
    color: #0d1218;
    font-weight: 700;
}
.reply_ttl {
	margin-bottom: 36px;
}
.blog_reply .em_contact_form {
	margin-bottom: 0;
}
.blog_reply .contact_bnt button:hover {
	background: #0D2352;
	color: #fff;
}
/* contact title css */
.request_form_title h2 {
	color: rgba(221, 221, 221, 0.79);
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	padding: 47px 0 7px;
}
.request_form_title h1 {
	color: rgba(255, 255, 255, 1);
	font-size: 35px;
	font-weight: 700;
	padding-bottom: 40px;
	text-transform: uppercase;
	margin: 0;
}

/* Form Css */
.blog_reply_coment_dtl .form_box input {
    border: 1px solid #e6e6e6;
    color: #495057;
	background-color: #fff;
}
.blog_reply_coment_dtl .form_box textarea {
    border: 1px solid #e6e6e6;
	color: #495057;
	background-color: #fff;
}
.blog_reply_coment_dtl .quote_btn button {
    background: var(--mainColor);
	color:#fff;
}
.blog_reply_coment_dtl .form_box input:focus,
.blog_reply_coment_dtl .form_box textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}


.paginations {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 28px;
    list-style: none;
    display: flex;
    justify-content: center;
}
.page-numbers li {
    display: inline-block;
}
.paginations a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: auto 5px;
    border: 1px solid #ddd;
    color: #888;
}
.paginations a:hover, .paginations li.active a {
    background: var(--mainColor);
    border-color: var(--mainColor);
    color: #fff !important;
}
.wedget_sideber_thumb img {
    width: 100%;
}
.widget_about_thumb{
	text-align:center;
}
.widget_about_content{
	text-align:center;
}
.widget_about_content h5 {
    font-weight: 700;
    padding-bottom: 3px;
}
.widget_about_icon{
	text-align:center;
}
.widget_about_icon a {
    display: inline-block;
    margin: 0 3px;
}
/*----------------------------------------*/
/*  Pre-loader
/*----------------------------------------*/
.pre-loader-wrap {
    background-color: #00247E;
    z-index: 9999;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  
  .pre-loader {
    --path: #fff;
    --dot: #fff;
    --duration: 3s;
    width: 75px;
    height: 75px;
    position: relative;
  }
  
  .pre-loader:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background: var(--dot);
    top: 37px;
    left: 19px;
    -webkit-transform: translate(-18px, -18px);
            transform: translate(-18px, -18px);
    -webkit-animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
            animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  
  .pre-loader span {
    display: block;
    margin-top: 10px;
  }
  
  .pre-loader svg {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .pre-loader svg rect,
  .pre-loader svg polygon,
  .pre-loader svg circle {
    fill: none;
    stroke: var(--path);
    stroke-width: 10px;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
  
  .pre-loader svg polygon {
    stroke-dasharray: 145 76 145 76;
    stroke-dashoffset: 0;
    -webkit-animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
            animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  
  .pre-loader svg rect {
    stroke-dasharray: 192 64 192 64;
    stroke-dashoffset: 0;
    -webkit-animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
            animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  
  .pre-loader svg circle {
    stroke-dasharray: 150 50 150 50;
    stroke-dashoffset: 75;
    -webkit-animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
            animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  
  .pre-loader.triangle {
    width: 80px;
  }
  
  .pre-loader.triangle:before {
    left: 21px;
    -webkit-transform: translate(-10px, -18px);
            transform: translate(-10px, -18px);
    -webkit-animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
            animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  
  @-webkit-keyframes pathTriangle {
    33% {
      stroke-dashoffset: 74;
    }
    66% {
      stroke-dashoffset: 147;
    }
    100% {
      stroke-dashoffset: 221;
    }
  }
  
  @keyframes pathTriangle {
    33% {
      stroke-dashoffset: 74;
    }
    66% {
      stroke-dashoffset: 147;
    }
    100% {
      stroke-dashoffset: 221;
    }
  }
  
  @-webkit-keyframes dotTriangle {
    33% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    66% {
      -webkit-transform: translate(10px, -18px);
              transform: translate(10px, -18px);
    }
    100% {
      -webkit-transform: translate(-10px, -18px);
              transform: translate(-10px, -18px);
    }
  }
  
  @keyframes dotTriangle {
    33% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    66% {
      -webkit-transform: translate(10px, -18px);
              transform: translate(10px, -18px);
    }
    100% {
      -webkit-transform: translate(-10px, -18px);
              transform: translate(-10px, -18px);
    }
  }
  
  @-webkit-keyframes pathRect {
    25% {
      stroke-dashoffset: 64;
    }
    50% {
      stroke-dashoffset: 128;
    }
    75% {
      stroke-dashoffset: 192;
    }
    100% {
      stroke-dashoffset: 256;
    }
  }
  
  @keyframes pathRect {
    25% {
      stroke-dashoffset: 64;
    }
    50% {
      stroke-dashoffset: 128;
    }
    75% {
      stroke-dashoffset: 192;
    }
    100% {
      stroke-dashoffset: 256;
    }
  }
  
  @-webkit-keyframes dotRect {
    25% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    50% {
      -webkit-transform: translate(18px, -18px);
              transform: translate(18px, -18px);
    }
    75% {
      -webkit-transform: translate(0, -36px);
              transform: translate(0, -36px);
    }
    100% {
      -webkit-transform: translate(-18px, -18px);
              transform: translate(-18px, -18px);
    }
  }
  
  @keyframes dotRect {
    25% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    50% {
      -webkit-transform: translate(18px, -18px);
              transform: translate(18px, -18px);
    }
    75% {
      -webkit-transform: translate(0, -36px);
              transform: translate(0, -36px);
    }
    100% {
      -webkit-transform: translate(-18px, -18px);
              transform: translate(-18px, -18px);
    }
  }
  
  @-webkit-keyframes pathCircle {
    25% {
      stroke-dashoffset: 125;
    }
    50% {
      stroke-dashoffset: 175;
    }
    75% {
      stroke-dashoffset: 225;
    }
    100% {
      stroke-dashoffset: 275;
    }
  }
  
  @keyframes pathCircle {
    25% {
      stroke-dashoffset: 125;
    }
    50% {
      stroke-dashoffset: 175;
    }
    75% {
      stroke-dashoffset: 225;
    }
    100% {
      stroke-dashoffset: 275;
    }
  }
  
  .pre-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #fff;
  }
  
#scrollUp {
    background: var(--mainColor) none repeat scroll 0 0;
    bottom: 30px;
    color: #fff;
    font-size: 22px;
    height: 40px;
    line-height: 40px;
    right: 100px;
    text-align: center;
    width: 40px;
    border-radius: 50%;
}
.intro__bg,
.intro__bg canvas {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

/* bounce-animate */
.bounce-animate {
    animation-name: float-bob;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
  .bounce-animate2 {
    animation-name: float-bob2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob2;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob2;
    -ms-animation-duration: 3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob2;
    -o-animation-duration: 3s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
  .bounce-animate3 {
    animation-name: float-bob3;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob3;
    -moz-animation-duration: 4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob3;
    -ms-animation-duration: 4s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob3;
    -o-animation-duration: 4s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
  .bounce-animate4 {
    animation-name: float-bob4;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob4;
    -moz-animation-duration: 5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob4;
    -ms-animation-duration: 5s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob4;
    -o-animation-duration: 5s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
   .bounce-animate5 {
    animation-name: float-bob5;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob5;
    -moz-animation-duration: 6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob5;
    -ms-animation-duration: 6s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob5;
    -o-animation-duration: 6s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
  @-webkit-keyframes float-bob {
    0% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px); }
    50% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px); }
    100% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px); } }
  @-webkit-keyframes float-bob2 {
    0% {
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px); }
    50% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px); }
    100% {
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px); } }
  @-webkit-keyframes float-bob3 {
    0% {
      -webkit-transform: translateY(-40px);
      transform: translateY(-40px); }
    50% {
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px); }
    100% {
      -webkit-transform: translateY(-40px);
      transform: translateY(-40px); } }
  @-webkit-keyframes float-bob4 {
    0% {
      -webkit-transform: translateY(-70px);
      transform: translateY(-70px); }
    50% {
      -webkit-transform: translateY(-35px);
      transform: translateY(-35px); }
    100% {
      -webkit-transform: translateY(-70px);
      transform: translateY(-70px); } }
  @-webkit-keyframes float-bob5 {
    0% {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px); }
    50% {
      -webkit-transform: translateY(-35px);
      transform: translateY(-35px); }
    100% {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px); } }
  @-webkit-keyframes movebounce {
    0% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  
  @keyframes movebounce {
    0% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  
  @-webkit-keyframes moveleftbounce {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  
  @keyframes moveleftbounce {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  
  .rotateme {
    -webkit-animation-name: rotateme;
            animation-name: rotateme;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  @keyframes rotateme {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes rotateme {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes rotate3d {
    0% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    100% {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  
  @keyframes rotate3d {
    0% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    100% {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  
  @keyframes animationFramesOne {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
              transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
      -webkit-transform: translate(73px, -1px) rotate(36deg);
              transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
      -webkit-transform: translate(141px, 72px) rotate(72deg);
              transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
      -webkit-transform: translate(83px, 122px) rotate(108deg);
              transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
      -webkit-transform: translate(-40px, 72px) rotate(144deg);
              transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
              transform: translate(0px, 0px) rotate(0deg);
    }
  }
  
  @-webkit-keyframes animationFramesOne {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
      -webkit-transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
      -webkit-transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
      -webkit-transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
      -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
  }
  

/*====================================================
--<	Mean Menu Css -->
======================================================*/

    a.meanmenu-reveal{display:none}
    .mean-container .mean-bar {
        background: #0762f8;
        float: left;
        min-height: 60px;
        padding: 4px 0 0;
        position: relative;
        width: 100%;
        z-index: 999999;
    }
    .mean-container .meanmenu-reveal{width:22px;height:22px;padding:18px;top:0;right:0;cursor:pointer;color:#fff;text-decoration:none;font-size:16px;text-indent:-9999em;line-height:22px;font-size:1px;display:block;font-family:Arial,Helvetica,sans-serif;font-weight:700;float: right;}
    .mean-container .meanmenu-reveal span{display:block;background:#fff;height:3px;margin-top:3px}
    .mean-container .mean-nav{float:left;width:100%;background:#0c1923}
    .mean-container .mean-nav ul{padding:0;margin:0;width:100%;list-style-type:none}
    .mean-container .mean-nav ul li {
      background: #f8f8f8 none repeat scroll 0 0;
      float: left;
      position: relative;
      width: 100%;
      border-top: 1px solid#ddd;
    }
    .mean-container .mean-nav .sub-menu-none{
        display: none;
    }
    .mean-container .mean-nav ul li a {
        background: #f8f8f8 none repeat scroll 0 0;
        color: #666666;
        display: block;
        float: left;
        font-size: 14px;
        margin: 0;
        padding: 1em 5%;
        text-align: left;
        text-decoration: none;
        text-transform: uppercase;
        width: 90%;
        font-weight: bold;
    }
    .mobile-menu-area {
      background: #262626;
    }
    .mean-container .mean-nav ul li li a {
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      opacity: 0.75;
      padding: 1em 10%;
      text-shadow: none !important;
      visibility: visible;
      width: 80%;
      font-weight: normal;
      text-transform: capitalize;
      color: #444;
    }
    .mean-container .mean-nav ul li.mean-last a{border-bottom:0;margin-bottom:0}
    .mean-container .mean-nav ul li li li a{width:70%;padding:1em 15%}
    .mean-container .mean-nav ul li li li li a{width:60%;padding:1em 20%}
    .mean-container .mean-nav ul li li li li li a{width:50%;padding:1em 25%}
    .mean-container .mean-nav ul li a:hover {
      background: #f8f8f8 none repeat scroll 0 0;
      color: #F47E1E;
    }
    .mean-container .mean-nav ul li .mean-expand{
        width:26px;
        height: 15px;
        margin-top: 1px;
        padding: 12px !important;
        text-align:center;
        position:absolute;
        right:0;top:0;
        z-index:2;
        font-weight:700;
        background:rgba(255,255,255,.1);
        border:0!important;
        background: #F8F8F8;
        }
    .mean-container .mean-nav ul li .mean-expand:hover {
      background: #f8f8f8;
    }
    .mean-container .mean-push{float:left;width:100%;padding:0;margin:0;clear:both}
    .mean-nav .wrapper{width:100%;padding:0;margin:0}
    .mean-container .mean-bar,.mean-container .mean-bar *{-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box}
    .mean-remove{display:none!important}
    .mean-container .mean-bar::after {
      color: #ffffff;
      content: "MENU";
      font-size: 21px;
      left: 5%;
      position: absolute;
      top: 12px;
    }
    
    
    /* -------------------------------
    39. Mobile menu
    ----------------------------------*/
    .mean-container .mean-bar::after {
      display: none;
    }
    .mean-container .mean-nav ul li a:hover {
        color: #0762f8;
        border-radius: 0;
    }
    .mean-container .meanmenu-reveal span {
      background: #fff;
    }
    .mean-container .meanclose span{
        transition: .3s;
        &:nth-child(1){
            transform: rotate(45deg) translate(2px, 2px);
        }
        &:nth-child(2){
            display: none;
        }
        &:nth-child(3){
            transform: rotate(-45deg);
        }
    }
    .mobile-menu-area {
      background: #fff none repeat scroll 0 0;
    }
    .mean-container .mean-nav ul li a {
      background: #fff;
      color: #444;
      display: block;
    }
    .mean-container .mean-nav ul li .mean-expand {
      background: none;
      top: -4px;
    }
    .mean-container .mean-nav ul li .mean-expand:hover {
      background: none;
    }
    .mean-container .mean-nav ul li {
      background: #444;
    }
    .mean-container .mean-nav ul li li a {
      color: #151948;
      opacity: .90;
      border-top: 0;
    }
    .mean-container .meanmenu-reveal {
      color: #fff;
    }
    .meanmenu-reveal.meanclose:hover {
      color: #fff !important;
    }
    .mean-container .meanmenu-reveal:hover span {
      background: #fff;
    }
    .mean-container .mean-bar::before {
        color: #fff;
        content: "Digicrew";
        font-size: 30px;
        font-weight: 800;
        left: 10px;
        position: absolute;
        top: 18px;
    }
    